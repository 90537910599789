import { useTranslation } from "react-i18next";
import { useCompare } from "../contexts/CompareContext";
import CompareCard from "../components/productsList/CompareCard";
import { Link } from "react-router-dom";
import NoProductsFound from "../components/productsList/NoProductsFound";

export default function CompareListPage() {
    const { t, i18n } = useTranslation();
    const { state } = useCompare();
    // Get the current language
    const language = i18n.resolvedLanguage;

    const totalProducts = Object.values(state.categorizedProducts).reduce(
        (total, products) => total + products.length,
        0,
    );

    // Add check for empty products
    if (
        !state.categorizedProducts ||
        Object.keys(state.categorizedProducts).length === 0 ||
        totalProducts == 0
    ) {
        return <NoProductsFound message={t("no_products_to_compare")} />;
    }

    // Remove the grouping logic since products are already grouped
    const groupedProducts = state.categorizedProducts;

    return (
        <div>
            {Object.entries(groupedProducts).map(([categoryId, products]) => {
                // Count selected products for this category
                const selectedCount = products.filter(
                    (p) => p.isSelected,
                ).length;

                return (
                    <section
                        key={categoryId}
                        className="container mx-auto px-4 py-8"
                    >
                        {/* Header with category name and compare button */}
                        <div className="mb-2 flex items-center justify-between">
                            <p className="me-10 text-2xl font-medium leading-10 text-primary-green">
                                {products[0][`category_name_${language}`]}
                            </p>
                            <Link
                                to={`/compare/${categoryId}`}
                                className="inline-flex items-center justify-center gap-2.5 rounded-md bg-primary-orange px-4 py-2"
                            >
                                <span className="hidden md:block">
                                    <CompareIcon />
                                </span>
                                <span className="font-heading text-center text-base md:text-lg font-medium leading-normal text-white">
                                    {t("compare")}
                                    {` ( ${selectedCount} / 4 )`}
                                </span>
                            </Link>
                        </div>

                        <div className="overflow-x-auto pt-5">
                            <div className="flex space-x-4 pb-4 ps-3 rtl:space-x-reverse">
                                {products.map((product) => (
                                    <div
                                        key={product.product_id}
                                        className="w-64 flex-shrink-0"
                                    >
                                        <CompareCard product={product} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                );
            })}
        </div>
    );
}

const CompareIcon = () => {
    return (
        <svg
            width="32"
            height="25"
            viewBox="0 0 32 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-7 cursor-pointer md:h-6 md:w-8"
        >
            <g id="swap2" clipPath="url(#clip0_1398_2218)">
                <path
                    id="Vector"
                    d="M25.8529 4.58322C25.2034 3.93966 24.5481 3.30197 23.9075 2.65059C23.4547 2.18993 23.3422 1.6295 23.5544 1.02702C23.7667 0.424541 24.2293 0.0812444 24.865 0.00691244C25.0905 -0.0225324 25.3196 0.00113469 25.5343 0.0760243C25.7489 0.150914 25.9431 0.274951 26.1013 0.438234C27.676 2.00312 29.2464 3.5742 30.8126 5.15147C30.9593 5.29188 31.0762 5.46047 31.1563 5.64714C31.2363 5.83381 31.2778 6.03471 31.2784 6.23781C31.2789 6.44092 31.2385 6.64205 31.1595 6.82914C31.0804 7.01624 30.9644 7.18546 30.8184 7.32666C29.2594 8.90784 27.689 10.4786 26.1072 12.0389C25.9634 12.1859 25.7915 12.3025 25.6017 12.3815C25.4119 12.4606 25.2081 12.5006 25.0025 12.4991C24.7969 12.4977 24.5937 12.4548 24.4051 12.373C24.2164 12.2913 24.0462 12.1723 23.9046 12.0233C23.7604 11.8776 23.6465 11.7049 23.5694 11.515C23.4923 11.3251 23.4536 11.1219 23.4554 10.9169C23.4572 10.712 23.4996 10.5095 23.58 10.321C23.6605 10.1325 23.7774 9.96181 23.9241 9.81874C24.5618 9.1654 25.2171 8.52869 25.9145 7.83427C25.7453 7.82155 25.6436 7.80786 25.5428 7.80786C22.7964 7.80786 20.0498 7.80786 17.3027 7.80786C15.4317 7.80786 14.1074 9.13605 14.0859 11.012C14.08 11.4883 14.0937 11.9646 14.0859 12.4399C14.0624 13.3906 13.4013 14.0557 12.4985 14.0459C11.5958 14.0361 10.9395 13.3495 10.9649 12.3979C10.9914 11.4139 10.9464 10.4017 11.1606 9.45294C11.7973 6.6029 14.3177 4.68885 17.3047 4.67809C20.0139 4.66929 22.7241 4.67809 25.4343 4.67809H25.8255L25.8529 4.58322Z"
                    fill="white"
                />
                <path
                    id="Vector_2"
                    d="M5.42037 20.4139C6.07176 21.0535 6.73488 21.6853 7.36963 22.3367C7.5273 22.4947 7.64917 22.6847 7.72697 22.8939C7.80476 23.1031 7.83667 23.3266 7.82053 23.5492C7.80439 23.7719 7.74058 23.9884 7.63342 24.1842C7.52626 24.38 7.37826 24.5505 7.19945 24.684C6.91041 24.9064 6.55121 25.0177 6.18708 24.9978C5.82295 24.9779 5.47804 24.828 5.21498 24.5755C3.60576 22.9917 2.00827 21.3942 0.422525 19.783C0.148525 19.5033 -0.00419794 19.1268 -0.00255028 18.7352C-0.000902626 18.3436 0.154983 17.9684 0.431327 17.691C2.00599 16.0909 3.5937 14.5032 5.19444 12.9279C5.33809 12.7852 5.50874 12.6726 5.69643 12.5966C5.88411 12.5207 6.08505 12.4829 6.2875 12.4856C6.48995 12.4882 6.68984 12.5312 6.87548 12.612C7.06112 12.6928 7.22878 12.8098 7.36865 12.9562C7.65921 13.243 7.82407 13.6333 7.827 14.0416C7.82993 14.4498 7.67071 14.8425 7.3843 15.1334C6.74172 15.8014 6.0698 16.44 5.35778 17.1433C5.5172 17.1609 5.60523 17.1785 5.69227 17.1785C8.46309 17.1785 11.2339 17.1853 14.0057 17.1785C15.8337 17.1736 17.1619 15.8503 17.1942 14.0194C17.202 13.5431 17.1942 13.0677 17.1942 12.5914C17.2079 11.6134 17.8602 10.9356 18.7727 10.9405C19.6853 10.9453 20.3425 11.6349 20.3171 12.6159C20.2917 13.5968 20.3337 14.5876 20.1215 15.5246C19.4857 18.3766 16.9692 20.2965 13.9852 20.3082C11.276 20.32 8.56579 20.3082 5.85658 20.3082H5.46536L5.42037 20.4139Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1398_2218">
                    <rect width="31.0432" height="25" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
