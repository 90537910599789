import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFilter } from "../../contexts/FilterContext.jsx";
import PropTypes from "prop-types";
import { Autocomplete } from "./Autocomplete.jsx";
import { COLLECTION_NAME, search } from "../../utils/typesenseClient.js";
import { SearchItem } from "./SearchItem.jsx";
import { SearchResponseAdapter } from "typesense-instantsearch-adapter/lib/SearchResponseAdapter";

const SearchBar = ({ additionalClass }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;

    const { state, dispatch } = useFilter();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState(state.search);

    useEffect(() => {
        setSearchValue(state.search);
    }, [state.search]);

    const handleSubmit = useCallback(
        (searchQuery) => {
            const trimmedValue = searchQuery.trim();

            // if the search query is not the same as the current search query, update the search query
            if (trimmedValue !== state.search) {
                dispatch({ type: "SET_SEARCH", payload: trimmedValue });
            }
            // reset the filter and navigate to the product page
            dispatch({ type: "RESET_FILTERS" });
            navigate("/product");
        },
        [dispatch, navigate, state.search],
    );

    const search_response_adapter = (result) =>
        new SearchResponseAdapter(
            result,
            { params: {} },
            { geoLocationField: "_geoloc" },
        );

    const getTypesenseSources = async (query) => {
        const results = await search(query);
        return [
            {
                sourceId: COLLECTION_NAME,
                getItems() {
                    return search_response_adapter(results).adapt().hits;
                },
                getItemUrl({ item }) {
                    return `${item[`name_${currentLanguage}`]}`;
                },
                templates: {
                    item({ item, components }) {
                        return (
                            <SearchItem
                                hit={item}
                                components={components}
                                onItemClick={handleSubmit}
                            />
                        );
                    },
                    noResults() {
                        return "No results found.";
                    },
                },
                getItemInputValue: ({ item }) => {
                    return item[`name_${currentLanguage}`];
                },
            },
        ];
    };

    return (
        <Autocomplete
            placeholder={t("search_hint")}
            openOnFocus={true}
            getSources={async ({ query }) => await getTypesenseSources(query)}
            // { state, event }
            onSubmit={({ state: autocompleteState }) =>
                handleSubmit(autocompleteState?.query)
            }
            initialState={{ query: searchValue }}
            className={`${additionalClass} font-lato text-base font-normal leading-normal text-secondary-font`}
            // defaultActiveItemId="0"
            navigator={{
                navigate({ itemUrl }) {
                    handleSubmit(itemUrl);
                },
            }}
        />
    );
};

export default SearchBar;

SearchBar.propTypes = {
    additionalClass: PropTypes.string.isRequired,
};
