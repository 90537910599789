import { useState, useEffect, useCallback } from "react";
import { PRICE_SLIDER_STEP } from "../utils/constants.js";

export const usePriceRangeSlider = (initialRange, apiRange, onChangeCallback) => {
    const [localRange, setLocalRange] = useState(initialRange);

    useEffect(() => {
        if (initialRange) {
            setLocalRange(initialRange);
        }
    }, [initialRange]);

    const validateAndAdjustRange = useCallback((newRange, index = null) => {
        let adjustedRange = [...newRange];

        // Ensure values are within API range
        adjustedRange[0] = Math.max(adjustedRange[0], apiRange[0]);
        adjustedRange[1] = Math.min(adjustedRange[1], apiRange[1]);

        // Handle value swapping if needed
        if (index === 0 && adjustedRange[0] > adjustedRange[1]) {
            [adjustedRange[0], adjustedRange[1]] = [adjustedRange[1], adjustedRange[0]];
        } else if (index === 1 && adjustedRange[1] < adjustedRange[0]) {
            [adjustedRange[0], adjustedRange[1]] = [adjustedRange[1], adjustedRange[0]];
        }

        // Round values to nearest step
        return adjustedRange.map(value => Math.round(value / PRICE_SLIDER_STEP) * PRICE_SLIDER_STEP);
    }, [apiRange]);

    const handleSliderChange = useCallback((newRange) => {
        setLocalRange(newRange);
    }, []);

    const handleSliderChangeComplete = useCallback((newRange) => {
        onChangeCallback(newRange);
    }, [onChangeCallback]);

    const handleInputChange = useCallback((index, value) => {
        const parsedValue = value ? parseInt(value, 10) : 0;
        // check if the value is a number
        if (!isNaN(parsedValue)) {
            const newRange = [...localRange];
            newRange[index] = parsedValue;
            setLocalRange(newRange);
        }
    }, [localRange]);

    const handleFinalValue = useCallback((index) => {
        const adjustedRange = validateAndAdjustRange(localRange, index);
        setLocalRange(adjustedRange);
        onChangeCallback(adjustedRange);
    }, [localRange, onChangeCallback, validateAndAdjustRange]);

    return {
        localRange,
        handleSliderChange,
        handleSliderChangeComplete,
        handleInputChange,
        handleFinalValue,
    };
};
