import { createContext, useReducer, useContext, useCallback } from "react";
import PropTypes from "prop-types";

// Create a context to hold the state and dispatch function
const CompareContext = createContext();

/*
    Reducer function to manage state changes
    it takes the current state and an action 
    and returns a new state based on the action type
*/
const compareReducer = (state, action) => {
    switch (action.type) {
        case "TOGGLE_COMPARE": {
            const categoryId = action.payload.category_id;
            const currentCategoryProducts = state.categorizedProducts[categoryId] || [];
            const productExists = currentCategoryProducts.some(
                (product) => product.product_id === action.payload.product_id
            );

            if (productExists) {
                // Remove product
                return {
                    ...state,
                    categorizedProducts: {
                        ...state.categorizedProducts,
                        [categoryId]: currentCategoryProducts.filter(
                            (product) => product.product_id !== action.payload.product_id
                        ),
                    },
                };
            } else {
                // Add product with automatic selection for first 4 products
                const updatedProducts = [...currentCategoryProducts, action.payload];
                return {
                    ...state,
                    categorizedProducts: {
                        ...state.categorizedProducts,
                        [categoryId]: updatedProducts.map((product, index) => ({
                            ...product,
                            isSelected: index < 4,
                        })),
                    },
                };
            }
        }

        case "TOGGLE_SELECTION": {
            const { product_id, category_id } = action.payload;
            return {
                ...state,
                categorizedProducts: {
                    ...state.categorizedProducts,
                    [category_id]: state.categorizedProducts[category_id].map((product) =>
                        product.product_id === product_id
                            ? { ...product, isSelected: !product.isSelected }
                            : product
                    ),
                },
            };
        }

        default:
            return state;
    }
};

// initialize the state object with categorized products
const initialState = {
    categorizedProducts: {},
};

// Provider component to provide state and dispatch function to children
export const CompareProvider = ({ children }) => {
    // Use useReducer hook to manage state, It accepts a reducer function and an initial state
    const [state, dispatch] = useReducer(compareReducer, initialState);

    // Provide state and dispatch function to children components via context
    const toggleCompare = useCallback((product) => {
        dispatch({ type: "TOGGLE_COMPARE", payload: product });
    }, []);

    return (
        <CompareContext.Provider value={{ state, toggleCompare, dispatch }}>
            {children}
        </CompareContext.Provider>
    );
};

// Custom hook to access state and dispatch function from context
// eslint-disable-next-line react-refresh/only-export-components
export const useCompare = () => {
    const context = useContext(CompareContext);
    if (!context) {
        throw new Error("useCompare must be used within a CompareProvider");
    }
    return context;
};

// Prop types for the CompareProvider component
CompareProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
