import { createContext, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import { SORT_OPTIONS, SORT_ORDER } from "../utils/constants";

// Create a context to hold the state and dispatch function
const FilterContext = createContext();

/*
    Reducer function to manage state changes
    it takes the current state and an action 
    and returns a new state based on the action type
*/
const filterReducer = (state, action) => {
    switch (action.type) {
        case "SET_BRANDS":
            return { ...state, brands: action.payload };
        case "SET_SELECTED_BRANDS":
            return { ...state, selectedBrands: action.payload, page: 1 };
        // for main category
        case "SET_MAIN_CATEGORIES":
            return { ...state, mainCategories: action.payload };
        case "SET_SELECTED_MAIN_CATEGORIES":
            return {
                ...state,
                selectedMainCategories: action.payload,
                selectedSubCategories: [],
                selectedBrands: [],
                page: 1,
            };
        // for sub category
        case "SET_SUB_CATEGORIES":
            return { ...state, subCategories: action.payload };
        case "SET_SELECTED_SUB_CATEGORIES":
            return { ...state, selectedSubCategories: action.payload, page: 1 };
        case "SET_STORES":
            return { ...state, stores: action.payload };
        case "SET_SELECTED_STORES":
            return { ...state, selectedStores: action.payload, page: 1 };
        case "SET_PRICE_RANGE":
            return { ...state, priceRange: action.payload, page: 1 };
        case "SET_API_PRICE_RANGE":
            return { ...state, apiPriceRange: action.payload };
        case "SET_SEARCH":
            return { ...state, search: action.payload, page: 1 };
        case "SET_PAGE":
            return { ...state, page: action.payload };
        case "SET_SORT_BY":
            return { ...state, sortBy: action.payload };
        case "SET_ORDER":
            return { ...state, order: action.payload };
        case "SET_LANGUAGE":
            return { ...state, lang: action.payload };
        case "RESET_SEARCH":
            return { ...state, search: "" };
        case "RESET_FILTERS":
            return {
                ...state,
                selectedBrands: [],
                selectedMainCategories: [0],
                selectedSubCategories: [],
                selectedStores: [],
                priceRange: state.apiPriceRange,
                page: 1,
                sortBy: SORT_OPTIONS.RELEVANCE,
                order: SORT_ORDER.DESC,
            };
        default:
            return state;
    }
};

// initialize the state object
const initialState = {
    brands: [],
    selectedBrands: [],
    // for main categories
    mainCategories: [],
    selectedMainCategories: [0],
    // for sub categories
    subCategories: [],
    selectedSubCategories: [],
    stores: [],
    selectedStores: [],
    // TODO: get the initial value from the API
    apiPriceRange: [0, 100000],
    priceRange: [0, 100000],
    // defaultPriceRange: [0, 100000], // Add this line to store the default price range
    search: "",
    page: 1,
    sortBy: SORT_OPTIONS.RELEVANCE,
    order: SORT_ORDER.DESC,
    lang: "en",
};

// Provider component to provide state and dispatch function to children
export const FilterProvider = ({ children }) => {
    // Use useReducer hook to manage state, It accepts a reducer function and an initial state
    const [state, dispatch] = useReducer(filterReducer, initialState);

    // Provide state and dispatch function to children components via context
    return (
        <FilterContext.Provider value={{ state, dispatch }}>
            {children}
        </FilterContext.Provider>
    );
};

FilterProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

// Custom hook to access state and dispatch function from context
// eslint-disable-next-line react-refresh/only-export-components
export const useFilter = () => useContext(FilterContext);
