import SEO from "../components/common/SEO.jsx";
import { useTranslation } from "react-i18next";

const AboutUsPage = () => {
    const { t } = useTranslation();

    const page = {
        title: t("about_us_page.title"),
        description: t("about_us_page.description"),
    };

    return (
        <div className="container mx-auto mb-8 p-6">
            <SEO title={page.title} description={page.description} />

            <section className="py-5 text-center">
                <h1 className="font-heading my-10 text-center text-5xl font-medium text-body-font">
                    {t("about_us_page.title")}
                </h1>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("about_us_page.who_we_are")}
                </h2>
                <p className="font-lato ms-6 text-justify text-lg font-normal leading-relaxed text-body-font">
                    {t("about_us_page.intro")}
                </p>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("about_us_page.features")}
                </h2>
                <ul className="font-lato ms-6 list-inside list-disc space-y-2 text-justify text-lg font-normal leading-relaxed text-body-font">
                    <li>{t("about_us_page.feature_1")}</li>
                    <li>{t("about_us_page.feature_2")}</li>
                    {/* <li>{t("about_us_page.feature_3")}</li> */}
                </ul>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("about_us_page.contact_us")}
                </h2>
                <div className="font-lato ms-6 text-justify text-lg font-normal leading-relaxed text-body-font">
                    <p>{t("about_us_page.contact_intro")}</p>
                    <p>
                        {t("about_us_page.contact_email")}&nbsp;
                        <a
                            href="mailto:contact@nexumind.com"
                            className="text-primary-orange hover:underline"
                        >
                            contact@nexumind.com
                        </a>
                    </p>
                </div>
            </section>
        </div>
    );
};

export default AboutUsPage;
