import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import ProductCard from "../productsList/ProductCard.jsx";

export default function ProductsSample({ products, title, onSeeMoreClick, currentLanguage }) {
    // for translation
    const { t } = useTranslation();

    return (
        <section className="container mx-auto px-4 py-8">
            <div className="mb-2 flex items-center">
                <p className="me-10 text-2xl font-medium leading-10 text-primary-green">
                    {title}
                </p>

                <button onClick={onSeeMoreClick} className="font-lato text-base font-normal leading-normal text-primary-orange">
                    {t("see_more")}
                </button>
            </div>

            <div className="overflow-x-auto lg:overflow-x-visible pt-5">
                <div className="flex space-x-4 rtl:space-x-reverse pb-4 ps-3 lg:grid lg:grid-cols-4 lg:gap-4 lg:space-x-0">
                    {products &&
                        products.map((product) => (
                            <div key={product.product_id} className="w-64 flex-shrink-0 lg:w-auto">
                                <ProductCard product={product} currentLanguage={currentLanguage} />
                            </div>
                        ))}
                </div>
            </div>
        </section>
    );
}

ProductsSample.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
        product_id: PropTypes.number.isRequired,
        // add other required properties here
    })).isRequired,
    title: PropTypes.string.isRequired,
    onSeeMoreClick: PropTypes.func.isRequired,
    currentLanguage: PropTypes.string.isRequired,
};
