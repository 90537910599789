import React from "react";
import { useTranslation } from "react-i18next";
import { useCompare } from "../contexts/CompareContext";
import { useParams } from "react-router-dom";
import { BASE_STORES_URL } from "../utils/constants";
import { useProductsCompare } from "../hooks/useFetch";
import Loading from "../components/common/Loading";
import LoadingError from "../components/common/LoadingError";
import { getLocalizedData } from "../utils/getLocalizedData";
import NoProductsFound from "../components/productsList/NoProductsFound";

export default function CompareDetailsPage() {
    const { t, i18n } = useTranslation();
    const { toggleCompare, state } = useCompare();

    const language = i18n.resolvedLanguage;
    const { categoryId } = useParams();

    // Filter products by category and selection
    const filteredProducts =
        state.categorizedProducts[categoryId]?.filter(
            (product) => product.isSelected,
        ) || [];

    // Map filtered products to their IDs
    const productsId = filteredProducts.map((product) => product.product_id);

    const {
        data: productsData,
        isLoading,
        error,
    } = useProductsCompare(productsId);

    if (isLoading) return <Loading />;
    if (error) return <LoadingError errorMessage={error.message} />;

    // Attributes to display
    const attributes = [
        { key: "name", type: "text" },
        { key: "price", type: "price" },
        { key: "brand", type: "object", subKey: "name" },
        { key: "Screen Size", type: "specification" },
        { key: "Battery Capacity", type: "specification" },
        { key: "Color", type: "specification" },
        { key: "RAM", type: "specification" },
        { key: "Processor", type: "specification" },
    ];

    const products = getLocalizedData(productsData, language);

    const productsLength = products.length;

    if (productsLength < 2) {
        return <NoProductsFound message={t("select_at_least_two_products")} />;
    }

    return (
        <div className="container mx-auto max-w-[1200px] px-2 py-4 sm:px-4 sm:py-8">
            <h1 className="font-heading my-4 mb-6 text-center text-2xl font-medium text-body-font sm:my-10 sm:mb-14 sm:text-4xl">
                {t("comparing")}{" "}
                {filteredProducts[0][`category_name_${language}`]}
            </h1>

            <div className="-mx-1 overflow-x-auto sm:mx-0">
                <div className=" px-2 sm:px-0">
                    <table
                        id="compare-table"
                        className="w-full border-collapse"
                    >
                        <thead className="rounded-lg border border-neutral-400/50">
                            <tr>
                                {products.map((product) => (
                                    <th
                                        key={product.id}
                                        className="relative flex-1 p-2"
                                    >
                                        <div className="mb-4 flex flex-col items-center justify-center">
                                            <img
                                                src={product.photo_url}
                                                alt={product[`name`]}
                                                loading="lazy"
                                                className="h-24 w-24 object-contain sm:h-32 sm:w-32"
                                            />

                                            {/* close button */}
                                            <div className="absolute right-0 top-0">
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        toggleCompare({
                                                            ...product,
                                                            product_id:product.id,
                                                            category_id: categoryId
                                                        })
                                                    }
                                                    className="text-gray-500 hover:text-gray-700"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="h-6 w-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M6 18 18 6M6 6l12 12"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>

                                            {/* store image */}
                                            <img
                                                src={
                                                    BASE_STORES_URL +
                                                    product.product_prices[0]
                                                        .store.logo
                                                }
                                                alt={
                                                    product.product_prices[0]
                                                        .store.name
                                                }
                                                className="absolute bottom-3 left-3 h-6 w-12 rounded bg-white object-contain shadow sm:h-7 sm:w-14"
                                            />
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {attributes.map((attr) => (
                                <React.Fragment key={attr.key}>
                                    <tr className="border text-center border-neutral-400/50 bg-stone-50 shadow-inner">
                                        <th
                                            colSpan={productsLength}
                                            className="font-heading px-3 py-2 text-center text-lg font-semibold leading-relaxed text-body-font sm:px-5 sm:py-2.5 sm:text-xl"
                                        >
                                            {t(attr.key)}
                                        </th>
                                    </tr>
                                    <tr className="border text-center border-neutral-400/50 bg-white py-2 shadow-inner sm:py-2.5">
                                        {productsData.map((product) => (
                                            <td
                                                key={product.id}
                                                className="font-lato border-x border-neutral-400/50 p-1.5 text-sm font-normal leading-normal text-body-font sm:p-2 sm:text-base"
                                            >
                                                {renderAttributeValue(
                                                    product,
                                                    attr,
                                                    language,
                                                    t,
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

function renderAttributeValue(product, attr, language, t) {
    switch (attr.type) {
        case "text":
            return product[`${attr.key}_${language}`] || t("not_available");
        case "price": {
            const price = product.product_prices[0];
            return price ? (
                <div>
                    <p>
                        {price.price} {price.currency[`name_${language}`]}
                    </p>
                    {price.discount > 0 && (
                        <>
                            <p className="text-sm text-gray-500 line-through">
                                {price.was_price}{" "}
                                {price.currency[`name_${language}`]}
                            </p>
                            <p className="text-sm text-green-600">
                                -{price.discount}%
                            </p>
                        </>
                    )}
                </div>
            ) : (
                t("not_available")
            );
        }
        case "object":
            return (
                product[attr.key][`${attr.subKey}_${language}`] ||
                t("not_available")
            );
        case "specification": {
            const spec = product.product_specifications.find(
                (s) => s[`specification_key_en`] === attr.key,
            );
            return spec
                ? spec[`specification_value_${language}`]
                : t("not_available");
        }
        default:
            return "-";
    }
}
