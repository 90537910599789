import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import DiscountLabel from "./DiscountLabel.jsx";
import { BASE_STORES_URL } from "../../utils/constants.js";
import CompareIcon from "./CompareIcon";
import { useCompare } from "../../contexts/CompareContext";

const ProductCard = ({ product, currentLanguage }) => {
    // Destructuring the product object to get the necessary properties
    const {
        product_id,
        price,
        was_price,
        discount,
        store_name,
        photo_url,
        name,
        currency,
        store_logo,
        category_id,
        store_id,
        category_name,
    } = product;

    // Compare context
    const { state, toggleCompare } = useCompare();

    // Check if the product is already in the compare list
    const isCompared = state.categorizedProducts[category_id]?.some(
        (p) => p.product_id === product_id
    ) || false;

    return (
        <div className="relative">
            <div className="relative flex h-full min-h-[16rem] flex-col justify-between rounded-lg border border-gray-200 bg-white p-2 text-body-font shadow md:min-h-[22.5rem]">
                {/* image and discount section */}
                <div className="relative h-36 md:h-48">
                    {/* image */}
                    <Link
                        to={`/product/${product_id}`}
                        className="relative flex h-full w-full cursor-pointer items-center justify-center border-b border-gray-200"
                    >
                        <img
                            src={photo_url}
                            alt={name}
                            loading="lazy"
                            className="h-full w-11/12 object-contain transition-transform duration-500 hover:scale-105"
                            height="192"
                            width="160"
                        />
                    </Link>

                    {/* discount label */}
                    {discount > 0 && (
                        <div className="absolute end-[calc(100%-94px)] top-[-22px] inline-flex h-28 w-28 flex-col">
                            {/* <DiscountLabel /> */}
                            <div className="transform rtl:scale-x-[-1]">
                                <DiscountLabel />
                            </div>

                            <span className="font-heading absolute end-[calc(100%-36px)] top-[25px] rotate-[-45deg] text-center text-base font-medium leading-normal text-white rtl:rotate-[45deg]">
                                {Math.floor(discount)}%
                            </span>
                        </div>
                    )}
                    {/* store image */}
                    <img
                        src={BASE_STORES_URL + store_logo}
                        alt={store_name}
                        className="absolute bottom-0 start-0 h-7 w-14 rounded bg-white object-contain shadow"
                    />
                </div>

                {/* product name and compare button section */}
                <div className="flex items-center justify-between gap-1">
                    <Link
                        className="font-heading m-0 line-clamp-2 flex-1 cursor-pointer text-base font-medium leading-normal text-body-font sm:m-2 md:text-lg"
                        to={`/product/${product_id}`}
                    >
                        {name}
                    </Link>

                    {/* compare button - only show for categories 3,4 and store not 2 */}
                    {(category_id === 3 || category_id === 4) && store_id !== 2 && (
                        <CompareIcon
                            isCompared={isCompared}
                            onClick={() =>
                                toggleCompare({
                                    ...product,
                                    [`store_name_${currentLanguage}`]: store_name,
                                    [`store_logo_${currentLanguage}`]: store_logo,
                                    [`category_name_${currentLanguage}`]: category_name,
                                    [`name_${currentLanguage}`]: name,
                                })
                            }
                        />
                    )}
                </div>

                {/* price section */}
                <div className="m-0 flex flex-wrap items-center justify-between gap-2 text-[14px] sm:m-2">
                    {/* price */}
                    <p className="font-heading text-center text-sm font-medium leading-normal text-body-font sm:text-lg md:text-base">
                        <span className="font-semibold">{price}</span>
                        <span className="mx-0.5">{currency}</span>
                    </p>

                    {/* was price */}
                    {discount > 0 && (
                        <p className="font-lato text-center text-xs font-normal leading-tight text-neutral-400 line-through md:text-sm">
                            {was_price} {currency}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

ProductCard.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    product: PropTypes.shape({
        product_id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        photo_url: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        was_price: PropTypes.number,
        discount: PropTypes.number.isRequired,
        store_name: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        store_logo: PropTypes.string.isRequired,
        category_name: PropTypes.string.isRequired,
        category_id: PropTypes.number.isRequired,
        store_id: PropTypes.number.isRequired,
    }).isRequired,
};

export default ProductCard;
