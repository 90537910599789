import PropTypes from "prop-types";

export const PriceInput = ({ 
    value, 
    index, 
    onChange, 
    onKeyDown, 
    onBlur, 
    placeholder, 
    ariaLabel 
}) => (
    <input
        type="text"
        value={value}
        onChange={(e) => onChange(index, e.target.value)}
        onKeyDown={(e) => onKeyDown(e, index)}
        onBlur={() => onBlur(index)}
        className="w-[45%] min-w-14 max-w-24 rounded border text-center outline-primary-green"
        placeholder={placeholder}
        aria-label={ariaLabel}
    />
);

PriceInput.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string.isRequired,
};
