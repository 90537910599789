import axios from "axios";

export const fetchCategories = async (selectedStores = []) => {
    const { data } = await axios.get("/api/products/categories", {
        params: {
            stores: selectedStores,
        },
    });
    return data;
};

export const fetchStores = async () => {
    const { data } = await axios.get("/api/products/stores");
    return data;
};

export const fetchOffers = async () => {
    const { data } = await axios.get("/api/products/offers");
    return data;
};

export const fetchProductDetails = async (productId) => {
    const { data } = await axios.get(`/api/products/${productId}`);
    return data;
};

export const fetchProducts = async (filters, productsLimit) => {
    const {
        selectedBrands,
        selectedMainCategories,
        selectedSubCategories,
        selectedStores,
        priceRange,
        search,
        page,
        sortBy,
        order,
        lang,
    } = filters;

    const { data } = await axios.get("/api/products", {
        params: {
            brands: selectedBrands,
            // check if the selectedMainCategories = [0] then send empty array
            mainCategories:
                selectedMainCategories.length === 1 &&
                selectedMainCategories[0] === 0
                    ? []
                    : selectedMainCategories,
            subCategories: selectedSubCategories,
            stores: selectedStores,
            priceRange,
            search,
            page,
            limit: productsLimit,
            sortBy,
            order,
            lang,
        },
    });
    return data;
};

export const fetchBrands = async (state) => {
    const { data } = await axios.get("/api/products/brands", {
        params: {
            mainCategories:
                state.selectedMainCategories.length === 1 &&
                state.selectedMainCategories[0] === 0
                    ? []
                    : state.selectedMainCategories,
            subCategories: state.selectedSubCategories,
            stores: state.selectedStores,
        },
    });
    return data;
};

export const fetchPriceRange = async (state) => {
    const { data } = await axios.get("/api/products/v2/price-range", {
        params: {
            mainCategories:
                state.selectedMainCategories.length === 1 &&
                state.selectedMainCategories[0] === 0
                    ? []
                    : state.selectedMainCategories,
            subCategories: state.selectedSubCategories,
            stores: state.selectedStores,
            brands: state.selectedBrands,
        },
    });
    return data;
};

export const fetchProductsCompare = async (productsId) => {
    const { data } = await axios.get("/api/products/compare", {
        params: {
            products: productsId,
        },
    });
    return data;
};
