import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "../../styles/loading-overlay.css";

export default function Loading() {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="loadingio-spinner-ripple-2by998twmg8">
                <div className="ldio-yzaezf3dcmj">
                    <div></div>
                    <div></div>
                </div>
            </div>

            <p className="mt-2 text-center font-heading font-semibold text-body-font">
                {t("loading")}
            </p>
        </div>
    );
}

Loading.propTypes = {
    isVisible: PropTypes.bool,
};
