import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import useIsMobile from "../../hooks/useIsMobile";

const SmartBanner = () => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (isMobile) {
            const timer = setTimeout(() => setIsVisible(true), 5000);
            return () => clearTimeout(timer);
        }
    }, [isMobile]);

    const hideBanner = () => {
        setIsVisible(false);
        localStorage.setItem("smartBannerClosed", "true");
    };

    useEffect(() => {
        const bannerClosed = localStorage.getItem("smartBannerClosed");
        if (bannerClosed === "true") {
            setIsVisible(false);
        }
    }, []);

    const getAppStoreUrl = () => {
        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            return "https://apps.apple.com/ye/app/arkhass/id6587583728";
        } else {
            return "https://play.google.com/store/apps/details?id=com.nexumind.arkhass";
        }
    };

    if (!isVisible || !isMobile) return null;

    return (
        // h-20
        <div className="flex h-[5.5rem] w-full items-center justify-between bg-primary-green p-1 text-white opacity-100 transition duration-500 ease-out">
            <div className="flex items-center">
                {/* close button */}
                <button
                    aria-label="Close"
                    className="close-button me-2 ms-1 rounded-full bg-primary-orange p-1"
                    type="button"
                    onClick={hideBanner}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlSpace="preserve"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        role="img"
                        fill="#fff"
                        height="16"
                        width="16"
                    >
                        <path d="M4.75 20c-.19 0-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l14.5-14.5c.29-.29.77-.29 1.06 0s.29.77 0 1.06l-14.5 14.5c-.15.15-.34.22-.53.22"></path>
                        <path d="M19.25 20c-.19 0-.38-.07-.53-.22L4.22 5.28c-.29-.29-.29-.77 0-1.06s.77-.29 1.06 0l14.5 14.5c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22"></path>
                    </svg>
                </button>
                {/* logo icon */}
                <div className="rounded-lg border bg-white p-2 shadow-sm">
                    <img
                        alt={t("app_banner.arkhass_app_icon")}
                        className="h-auto w-12"
                        src="/arkhass.svg"
                    />
                </div>
                {/* app download text */}
                <div
                    // className={`ms-4 ${language === "ar" && "my-4"}`}
                    className="ms-4"
                >
                    <p className="font-heading text-lg font-bold">
                        {t("app_banner.app_name")}
                    </p>
                    <p className="font-lato mb-0" style={{ fontSize: "14px" }}>
                        {t("app_banner.message")}
                    </p>
                </div>
            </div>

            <a
                aria-disabled="false"
                className="font-lato mx-1 transform rounded-full bg-primary-orange px-5 py-3 font-semibold leading-tight text-white transition duration-300 ease-in-out hover:bg-[#f0780f] focus:outline-none"
                href={getAppStoreUrl()}
                role="button"
                tabIndex="0"
                target="_blank"
            >
                {t("app_banner.open_app")}
            </a>
        </div>
    );
};

export default SmartBanner;
