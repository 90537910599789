import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../styles/loading-overlay.css";
import errorImage from "../../assets/error404.svg";

export default function LoadingError({ errorMessage }) {
    const { t } = useTranslation();

    return (
        <div className="container mx-auto my-8 text-center">
            <h1 className="relative z-[2] mb-0 pb-0 pt-20 font-heading text-lg font-semibold leading-loose text-body-font">
                {errorMessage}
            </h1>

            <img
                src={errorImage}
                className="mx-auto w-80"
                alt={t("error_loading")}
            />

            <Link
                to={"/"}
                className="mt-4 inline-block transform rounded-lg bg-primary-green px-5 py-3 text-white transition duration-300 ease-in-out hover:bg-[#466d6d] focus:outline-none"
            >
                {t("go_home")}
            </Link>
        </div>
    );
}

LoadingError.propTypes = {
    errorMessage: PropTypes.string,
};
