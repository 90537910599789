import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import notFound from "../../assets/notFound.svg";

const NoProductsFound = ({ message }) => {
    const { t } = useTranslation();

    return (
        <div className="container mx-auto mb-16 text-center">
            <h1 className="font-heading relative z-[2] mb-0 pb-0 pt-20 text-lg font-medium leading-loose text-body-font">
                {message}
            </h1>
            <img
                src={notFound}
                className="mx-auto w-80"
                alt="No products found"
            />
            <Link
                to="/"
                className="transform rounded-lg bg-primary-green px-5 py-3 text-white transition duration-300 ease-in-out hover:bg-[#308192] focus:outline-none"
            >
                {t("go_home")}
            </Link>
        </div>
    );
};

NoProductsFound.propTypes = {
    message: PropTypes.string.isRequired,
};

export default NoProductsFound;
