import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export function SearchItem({ hit, components, onItemClick }) {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;

    const handleClick = (e) => {
        e.preventDefault();
        onItemClick(hit[`name_${currentLanguage}`]);
    };

    return (
        <a href="#" onClick={handleClick} className="aa-ItemLink">
            <div className="aa-ItemContent">
                <div className="aa-ItemTitle">
                <components.Highlight hit={hit} attribute={`name_${currentLanguage}`} />
                {/* <components.Snippet hit={hit} attribute={`name_${currentLanguage}`} /> */}
                {/* {components.ReverseHighlight({ hit: hit, attribute: `name_${currentLanguage}` })} */}
                </div>
            </div>
        </a>
    );
}

SearchItem.propTypes = {
    hit: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    onItemClick: PropTypes.func.isRequired,
};
