// import { useEffect, useState, useCallback, useMemo, memo } from "react";
import { useEffect, useCallback, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useFilter } from "../../contexts/FilterContext.jsx";
import { PriceRangeSlider } from "./PriceRangeSlider.jsx";
import { BASE_STORES_URL } from "../../utils/constants.js";
import FilterSection from "./FilterSection.jsx";

import {
    useCategories,
    useBrands,
    useStores,
    usePriceRange,
} from "../../hooks/useFetch.js";
import { getLocalizedData } from "../../utils/getLocalizedData.js";
import { capitalizeFirstLetter } from "../../utils/stringUtils.js";
import { COLORS } from "../../utils/colors.js";

// eslint-disable-next-line react-refresh/only-export-components
const Filters = ({ isVisible, onFilterClick }) => {
    // for translation
    const { t, i18n } = useTranslation();
    // Get the current language
    const language = i18n.resolvedLanguage;

    const { state, dispatch } = useFilter();

    const { data: dataCategories } = useCategories({
        selectedStores: state?.selectedStores || [],
    });

    const { data: dataStores } = useStores();

    const { data: dataBrands } = useBrands({
        selectedMainCategories: state.selectedMainCategories,
        selectedSubCategories: state.selectedSubCategories,
        selectedStores: state.selectedStores,
    });

    // get the price main and max price form the api
    const { data: dataPriceRange } = usePriceRange({
        selectedMainCategories: state.selectedMainCategories,
        selectedSubCategories: state.selectedSubCategories,
        selectedBrands: state.selectedBrands,
        selectedStores: state.selectedStores,
    });

    useEffect(() => {
        if (dataCategories && dataBrands && dataStores) {
            dispatch({ type: "SET_CATEGORIES", payload: dataCategories });
            dispatch({ type: "SET_STORES", payload: dataStores });
            dispatch({ type: "SET_BRANDS", payload: dataBrands });
        }
    }, [dataCategories, dataStores, dataBrands, dispatch]);

    useEffect(() => {
        if (dataPriceRange) {
            dispatch({ type: "SET_API_PRICE_RANGE", payload: dataPriceRange });
            dispatch({ type: "SET_PRICE_RANGE", payload: dataPriceRange });
        }
    }, [dataPriceRange, dispatch]);

    const handleBrandChange = useCallback(
        (brandId) => {
            dispatch({
                type: "SET_SELECTED_BRANDS",
                payload: toggleItem(state.selectedBrands, brandId),
            });
        },
        [state.selectedBrands, dispatch],
    );

    // for main category selection
    const handleMainCategoryChange = useCallback(
        (categoryId) => {
            dispatch({
                type: "SET_SELECTED_MAIN_CATEGORIES",
                payload: [categoryId],
            });
        },
        [dispatch],
    );

    // for sub category selection
    const handleSubCategoryChange = useCallback(
        (categoryId) => {
            dispatch({
                type: "SET_SELECTED_SUB_CATEGORIES",
                payload: toggleItem(state.selectedSubCategories, categoryId),
            });
        },
        [state.selectedSubCategories, dispatch],
    );

    const handleStoreChange = useCallback(
        (storeId) => {
            dispatch({
                type: "SET_SELECTED_STORES",
                payload: toggleItem(state.selectedStores, storeId),
            });
        },
        [state.selectedStores, dispatch],
    );

    const handlePriceRangeChange = useCallback(
        (newRange) => {
            dispatch({ type: "SET_PRICE_RANGE", payload: newRange });
        },
        [dispatch],
    );

    const handleResetFilters = useCallback(() => {
        dispatch({ type: "RESET_FILTERS" });
    }, [dispatch]);

    const areFiltersActive = useMemo(() => {
        return (
            state.selectedBrands.length > 0 ||
            (state.selectedMainCategories.length > 0 &&
                state.selectedMainCategories[0] !== 0) ||
            state.selectedSubCategories.length > 0 ||
            state.selectedStores.length > 0 ||
            state.priceRange[0] !== state.apiPriceRange[0] ||
            state.priceRange[1] !== state.apiPriceRange[1]
        );
    }, [state]);

    // Get localized product data
    const categories = dataCategories
        ? getLocalizedData(dataCategories, language)
        : undefined;
    const brands = dataBrands
        ? getLocalizedData(dataBrands, language)
        : undefined;
    const stores = dataStores
        ? getLocalizedData(dataStores, language)
        : undefined;

    // order the  brands by name
    brands?.sort((a, b) => a.name.localeCompare(b.name));

    // first category all categories
    const level1 = [{ id: 0, name: t("filter.all_categories") }];
    const level2 = [];

    /* 
    extract the category level 1 and level 2 based on the selected main category 
    and if the main category is 0 (all categories) then show all level 2 categories
    */
    if (categories) {
        categories.forEach((category) => {
            category.subcategories_level1?.forEach((subcategory1) => {
                level1.push(subcategory1);
                if (
                    state.selectedMainCategories.length > 0 &&
                    (state.selectedMainCategories.includes(subcategory1.id) ||
                        state.selectedMainCategories.includes(0))
                ) {
                    subcategory1.subcategories_level2?.forEach(
                        (subcategory2) => {
                            level2.push(subcategory2);
                        },
                    );
                }
            });
        });
    }

    return (
        <>
            <div
                className={`fixed inset-0 bg-black lg:hidden ${
                    isVisible ? "z-50 opacity-50" : "-z-10 opacity-0"
                }`}
                onClick={onFilterClick}
            ></div>

            <aside
                className={`col-span-12 transform rounded-lg border bg-white shadow transition-transform duration-300 lg:col-span-3 lg:transform-none ${
                    isVisible
                        ? "translate-x-0"
                        : "-translate-x-full lg:translate-x-0 rtl:translate-x-full rtl:lg:translate-x-0"
                } fixed start-0 top-0 z-50 h-full overflow-y-auto lg:static lg:z-auto lg:h-auto lg:w-auto`}
            >
                <div className="my-2 rounded-lg border-b-2 bg-white px-4">
                    {/* filter title and rest filter section */}
                    <div className="flex items-center justify-between p-4">
                        <div className="flex items-center">
                            <svg
                                width="16"
                                height="15"
                                className="me-2 h-5 w-5"
                                viewBox="0 0 16 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10.0011 7.49758V13.703C10.0411 13.9393 9.94111 14.1913 9.71111 14.3566C9.61859 14.4296 9.50871 14.4875 9.38773 14.5271C9.26676 14.5666 9.13708 14.5869 9.00611 14.5869C8.87514 14.5869 8.74546 14.5666 8.62448 14.5271C8.50351 14.4875 8.39362 14.4296 8.30111 14.3566L6.29111 12.7738C6.18211 12.6898 6.09922 12.5871 6.04892 12.4737C5.99861 12.3603 5.98225 12.2393 6.00111 12.1202V7.49758H5.97111L0.211108 1.68589C0.0487158 1.52172 -0.0245586 1.31361 0.00729555 1.10703C0.0391497 0.90045 0.173543 0.712196 0.381108 0.583404C0.571108 0.473156 0.781108 0.410156 1.00111 0.410156H15.0011C15.2211 0.410156 15.4311 0.473156 15.6211 0.583404C15.8287 0.712196 15.9631 0.90045 15.9949 1.10703C16.0268 1.31361 15.9535 1.52172 15.7911 1.68589L10.0311 7.49758H10.0011Z"
                                    fill={COLORS.primary.orange}
                                />
                            </svg>
                            <span className="font-heading text-lg font-medium leading-loose text-primary-green">
                                {t("filter.filter")}
                            </span>
                        </div>

                        <div className="flex">
                            <button
                                onClick={handleResetFilters}
                                className={`font-heading me-6 text-base font-medium leading-tight lg:me-0 ${areFiltersActive ? "text-orange-400" : "cursor-not-allowed text-gray-400"}`}
                                disabled={!areFiltersActive}
                            >
                                {t("filter.reset")}
                            </button>
                            {/* close btn */}
                            <svg
                                className="block cursor-pointer text-orange-400 lg:hidden"
                                height="1.4rem"
                                width="1.4rem"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                                onClick={onFilterClick}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="mx-4">
                    {/* price filter */}
                    <FilterSection title={t("filter.price")}>
                        <PriceRangeSlider onChange={handlePriceRangeChange} />
                    </FilterSection>

                    {/* main category filter */}
                    <FilterSection
                        title={t("filter.main_category")}
                        items={level1}
                        selectedItems={state.selectedMainCategories}
                        onChange={handleMainCategoryChange}
                        renderItem={renderMainCategoryItem}
                    />

                    {/* sub category filter */}
                    <FilterSection
                        title={t("filter.sub_category")}
                        items={level2}
                        selectedItems={state.selectedSubCategories}
                        onChange={handleSubCategoryChange}
                    />

                    {/* brand filter */}
                    <FilterSection
                        title={t("filter.brand")}
                        items={brands}
                        selectedItems={state.selectedBrands}
                        onChange={handleBrandChange}
                    />

                    {/* store filter */}
                    <FilterSection
                        title={t("filter.store")}
                        items={stores}
                        selectedItems={state.selectedStores}
                        onChange={handleStoreChange}
                        renderItem={renderStoreItem}
                        maxItems={5}
                    />
                </div>
            </aside>
        </>
    );
};

Filters.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onFilterClick: PropTypes.func.isRequired,
};

const toggleItem = (list, item) => {
    return list.includes(item)
        ? list.filter((i) => i !== item)
        : [...list, item];
};

// for store item design
const renderStoreItem = (store, isSelected, onChange) => (
    <div
        className="mb-2 flex items-center justify-start gap-3.5"
        key={store.id}
        onClick={() => onChange(store.id)}
    >
        <div className="flex items-center gap-2">
            <div className="h-9 w-14 flex-shrink-0">
                <img
                    className={`h-full w-full cursor-pointer rounded border-[1.5px] object-contain hover:border-primary-orange hover:opacity-100 ${
                        isSelected
                            ? "border-primary-orange opacity-100"
                            : "border-gray-400 opacity-50"
                    }`}
                    src={`${BASE_STORES_URL}${store.logo}`}
                    alt={store.name}
                />
            </div>
            <p
                className={`font-lato cursor-pointer text-base font-normal leading-normal ${
                    isSelected
                        ? "text-primary-green"
                        : "text-body-font hover:text-primary-green"
                }`}
            >
                {capitalizeFirstLetter(store.name)}
            </p>
        </div>
    </div>
);

// for Main Category item design
const renderMainCategoryItem = (mainCategory, isSelected, onChange) => (
    <div
        className="mb-3 flex items-center hover:text-primary-green"
        key={mainCategory.id}
        onClick={() => onChange(mainCategory.id)}
    >
        <input
            id={`main_${mainCategory.id}`}
            value={mainCategory.name}
            type="radio"
            checked={isSelected}
            onChange={() => onChange(mainCategory.id)}
            className="h-4 w-4 cursor-pointer rounded border outline-none"
        />
        <label
            htmlFor={`main_${mainCategory.id}`}
            className="font-lato mx-2 cursor-pointer"
        >
            {mainCategory.name}
        </label>
    </div>
);

// eslint-disable-next-line react-refresh/only-export-components
export default memo(Filters);
