import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

/**
 * SEO component that defines metadata for the page.
 *
 * @param {string} title - The title of the page.
 * @param {string} description - The description of the page.
 * @param {string} name - The name of the author.
 * @param {string} type - The type of the page.
 * @returns {JSX.Element}
 */

function SEO({ title, description, name = "arkhass", type = "website" }) {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.resolvedLanguage === 'ar';

    const keywords = [
        t('keywords.best_prices'),
        t('keywords.price_comparison'),
        t('keywords.best_website'),
        t('keywords.discounts'),
        t('keywords.electronics')
    ].join(', ');

    const alternateKeywords = isArabic ? 
        "Best Prices and Offers, Price Comparison, Best Price Comparison Website in Saudi Arabia, Discounts, Electronics" :
        "أفضل الأسعار والعروض, مقارنة الأسعار, أفضل موقع مقارنة أسعار في السعودية, تخفيضات, إلكترونيات";

    return (
        <Helmet>
            {/* Standard metadata tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="keywords:alternate" content={alternateKeywords} />

            {/* OpenGraph tags */}
            <meta property="og:locale" content={isArabic ? "ar_AR" : "en_US"} />
            <meta property="og:locale:alternate" content={isArabic ? "en_US" : "ar_AR"} />
            <meta property="og:type" content={type} />

            <meta property="og:title" content={title} />
            <meta property="og:title:alternate" content={title} />

            <meta property="og:description" content={description} />
            <meta property="og:description:alternate" content={description} />

            {/* <meta property="og:url" content="https://arkhass.com/" /> */}
            {/* <meta property="og:url:alternate" content="https://arkhass.com/" /> */}

            {/* <meta property="og:image" content={ogImage} /> */}
            {/* <meta property="og:image:type" content="image/png" /> */}
            {/* <meta property="og:image:alt" content={isArabic ? "أرخــص" : "Arkhass"} /> */}

            {/* <meta property="og:image:alternate" content={ogImage} /> */}
            {/* <meta property="og:image:type:alternate" content="image/png" /> */}
            {/* <meta property="og:image:alt:alternate" content={isArabic ? "أرخــص" : "Arkhass"} /> */}

            {/* Twitter tags */}
            {/* <meta name="twitter:card" content="summary_large_image" /> */}

            <meta name="twitter:creator" content={name} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:title:alternate" content={title} />

            <meta name="twitter:description" content={description} />
            <meta name="twitter:description:alternate" content={description} />

            {/* <meta name="twitter:url" content="https://arkhass.com/" /> */}
            {/* <meta name="twitter:url:alternate" content="https://arkhass.com/" /> */}

            {/* <meta name="twitter:image" content={ogImage} /> */}
            {/* <meta property="twitter:image:type" content="image/png" /> */}
            {/* <meta property="twitter:image:alt" content={isArabic ? "أرخــص" : "Arkhass"} /> */}

            {/* <meta name="twitter:image:alternate" content={ogImage} /> */}
            {/* <meta property="twitter:image:type:alternate" content="image/png" /> */}
            {/* <meta property="twitter:image:alt:alternate" content={isArabic ? "أرخــص" : "Arkhass"} /> */}
        </Helmet>
    );
}

SEO.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
};

// eslint-disable-next-line react-refresh/only-export-components
export default SEO;
