import { BASE_CATEGORIES_URL } from "../../utils/constants.js";
import PropTypes from "prop-types";
import { useFilter } from "../../contexts/FilterContext.jsx";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({ subcategory }) => {
    const { dispatch } = useFilter();
    const navigate = useNavigate();

    // to select category in productsList page
    const handleCategoryClick = (mainCategoryId, subcategoryId) => {
        dispatch({
            type: "SET_SELECTED_MAIN_CATEGORIES",
            payload: [mainCategoryId],
        });
        dispatch({
            type: "SET_SELECTED_SUB_CATEGORIES",
            payload: [subcategoryId],
        });
        navigate("/product");
        // navigate(`/product/${subcategory.id}`);
    };

    return (
        <div
            className="flex flex-col items-center justify-center gap-2 w-full max-w-80 cursor-pointer"
            onClick={() =>
                handleCategoryClick(
                    subcategory.parent_category_id,
                    subcategory.id,
                )
            }
        >
            <div className="w-full aspect-square overflow-hidden rounded-3xl">
                <img
                    src={`${BASE_CATEGORIES_URL}${subcategory.image}`}
                    alt={subcategory.name}
                    className="w-full h-full object-cover"
                />
            </div>
            <p className="font-heading text-base font-medium leading-tight text-black text-center">
                {subcategory.name}
            </p>
        </div>
    );
};

CategoryCard.propTypes = {
    subcategory: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parent_category_id: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
};

export default CategoryCard;
