import PropTypes from "prop-types";
import { BASE_STORES_URL } from "../../utils/constants.js";
import { useCompare } from "../../contexts/CompareContext";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify'; // Import toast

const CompareCard = ({ product }) => {
    // for translation
    const { t, i18n } = useTranslation();
    // Get the current language
    const language = i18n.resolvedLanguage;

    // Destructuring the product object to get the necessary properties
    const { product_id, photo_url, isSelected, category_id } = product;

    // Compare context
    const { toggleCompare, dispatch, state } = useCompare();

    // Count selected products in this category
    const selectedCount = state.categorizedProducts[category_id]?.filter(p => p.isSelected).length || 0;

    const handleSelectionChange = () => {
        if (!isSelected && selectedCount >= 4) {
            // Show toast notification
            toast.error(t('maxProductsError', { count: 4 }));
            return;
        }

        dispatch({
            type: "TOGGLE_SELECTION",
            payload: { product_id, category_id },
        });
    };

    CompareCard.propTypes = {
        product: PropTypes.shape({
            product_id: PropTypes.number.isRequired,
            [`name_${language}`]: PropTypes.string.isRequired,
            photo_url: PropTypes.string.isRequired,
            [`store_name_${language}`]: PropTypes.string.isRequired,
            [`store_logo_${language}`]: PropTypes.string.isRequired,
            [`category_name_${language}`]: PropTypes.string.isRequired,
            isSelected: PropTypes.bool.isRequired,
            category_id: PropTypes.number.isRequired,
        }).isRequired,
    };

    return (
        <div className="relative h-full">
            <div className="relative flex h-full flex-col justify-between rounded-lg border border-gray-200 bg-white p-2 text-body-font shadow">
                {/* image and discount section */}
                <div className="relative h-36 md:h-48">
                    {/* image */}
                    <div className="relative flex h-full w-full items-center justify-center border-b border-gray-200">
                        <img
                            src={photo_url}
                            alt={product[`name_${language}`]}
                            loading="lazy"
                            className="h-full w-11/12 object-contain"
                            height="192"
                            width="160"
                        />
                    </div>

                    {/* check and close buttons */}
                    {/* close button */}
                    <div className="absolute end-0 top-0">
                        <button
                            type="button"
                            onClick={() => toggleCompare(product)}
                            className="text-gray-500 hover:text-gray-700"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18 18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>

                    {/* check box */}
                    <div className="absolute start-0 top-0">
                        <input
                            id={`product-${product_id}`}
                            value={product[`name_${language}`]}
                            type="checkbox"
                            checked={product.isSelected}
                            onChange={handleSelectionChange}
                            name="product"
                            className="h-6 w-6 cursor-pointer rounded-sm border-primary-green bg-primary-green text-primary-green outline-none focus:ring-primary-green"
                        />
                    </div>

                    {/* store image */}
                    <img
                        src={
                            BASE_STORES_URL + product[`store_logo_${language}`]
                        }
                        alt={product[`store_name_${language}`]}
                        className="absolute bottom-0 start-0 h-7 w-14 rounded bg-white object-contain shadow"
                    />
                </div>

                {/* product name - added fixed height and truncate */}
                <div className="h-12 my-2 flex items-center justify-between gap-1">
                    <div className="line-clamp-2 text-base">
                        {product[`name_${language}`]}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompareCard;
