import { autocomplete } from "@algolia/autocomplete-js";
import "@algolia/autocomplete-theme-classic";
import { createElement, Fragment, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";

// Add this import
import "../../styles/Autocomplete.css";
import { t } from "i18next";

export function Autocomplete(options) {
    const { className, ...props } = options;

    const containerRef = useRef(null);
    const panelRootRef = useRef(null);
    const rootRef = useRef(null);

    useEffect(() => {
        if (!containerRef.current) {
            return undefined;
        }

        const search = autocomplete({
            container: containerRef.current,
            renderer: {
                createElement,
                Fragment,
                render: () => {},
            },
            render({ children }, root) {
                if (!panelRootRef.current || rootRef.current !== root) {
                    rootRef.current = root;

                    panelRootRef.current?.unmount();
                    panelRootRef.current = createRoot(root);
                }
                panelRootRef.current.render(children);
            },
            translations: {
                clearButtonTitle: t("clear_button_title"),
                detachedCancelButtonText: t("cancel_button_title"),
                submitButtonTitle: t("submit_button_title"),
            },
            ...props,
        });

        return () => {
            search.destroy();
        };
    }, [props]);

    return <div ref={containerRef} className={className} />;
}
