// Import the colors from your Tailwind config
export const COLORS = {
    primary: {
        green: '#38a2b3',
        orange: '#fb8500',
    },
    secondary: {
        orange: {
            border: 'rgba(242, 143, 59, 0.3)',
        }
    },
    text: {
        body: '#263C3C',
        secondary: '#989696',
    },
    utility: {
        gray: '#f9fafb',
        stroke: '#dfe4ea',
    }
};
