// PrivacyPolicyPage.js
import { useTranslation } from "react-i18next";
import SEO from "../components/common/SEO.jsx";

const PrivacyPolicyPage = () => {
    const { t } = useTranslation();

    const page = {
        title: t("privacyPolicy.seoTitle"),
        description: t("privacyPolicy.seoDescription"),
    };

    return (
        <div className="container mx-auto mb-8 p-6 font-lato text-base">
            <SEO title={page.title} description={page.description} />

            <section className="py-5 text-center">
                <h1 className="font-heading my-10 text-center text-5xl font-medium text-body-font">
                    {t("privacyPolicy.title")}
                </h1>
                <p className="text-gray-500">
                    {t("privacyPolicy.lastUpdated")}
                </p>
            </section>

            <section className="py-5">
                <p className="text-body-font">
                    {t("privacyPolicy.intro.paragraph1")}
                </p>
                <p className="text-body-font">
                    {t("privacyPolicy.intro.paragraph2")}
                </p>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("privacyPolicy.interpretation.title")}
                </h2>
                <h3 className="font-heading mb-2 text-2xl font-medium leading-8 text-body-font">
                    {t("privacyPolicy.interpretation.interpretationTitle")}
                </h3>
                <p className="text-body-font">
                    {t("privacyPolicy.interpretation.interpretationText")}
                </p>
                <h3 className="font-heading mb-2 text-2xl font-medium leading-8 text-body-font">
                    {t("privacyPolicy.interpretation.definitionsTitle")}
                </h3>
                <p className="text-body-font">
                    {t("privacyPolicy.interpretation.definitionsIntro")}
                </p>
                <ul className="list-inside list-disc ps-4">
                    {t("privacyPolicy.interpretation.definitionsList", {
                        returnObjects: true,
                    }).map((item, index) => (
                        <li className="text-body-font" key={index}>
                            <strong>{item.term}</strong> {item.definition}
                        </li>
                    ))}
                </ul>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("privacyPolicy.collecting.title")}
                </h2>
                <h3 className="font-heading mb-2 text-2xl font-medium leading-8 text-body-font">
                    {t("privacyPolicy.collecting.typesTitle")}
                </h3>
                <h4 className="font-heading mb-2 text-xl font-medium leading-6 text-body-font">
                    {t("privacyPolicy.collecting.personalDataTitle")}
                </h4>
                <p className="text-body-font">
                    {t("privacyPolicy.collecting.personalDataText")}
                </p>
                <ul className="list-inside list-disc ps-4">
                    <li className="text-body-font">
                        {t("privacyPolicy.collecting.personalDataItem")}
                    </li>
                </ul>
                <h4 className="font-heading mb-2 text-xl font-medium leading-6 text-body-font">
                    {t("privacyPolicy.collecting.usageDataTitle")}
                </h4>
                <p className="text-body-font">
                    {t("privacyPolicy.collecting.usageDataText1")}
                </p>
                <p className="text-body-font">
                    {t("privacyPolicy.collecting.usageDataText2")}
                </p>
                <p className="text-body-font">
                    {t("privacyPolicy.collecting.usageDataText3")}
                </p>
                <p className="text-body-font">
                    {t("privacyPolicy.collecting.usageDataText4")}
                </p>
            </section>

            <section className="py-5">
                <h3 className="font-heading mb-2 text-2xl font-medium leading-8 text-body-font">
                    {t("privacyPolicy.use.title")}
                </h3>
                <p className="text-body-font">{t("privacyPolicy.use.intro")}</p>
                <ul className="list-inside list-disc ps-4">
                    {t("privacyPolicy.use.purposes", {
                        returnObjects: true,
                    }).map((item, index) => (
                        <li className="text-body-font" key={index}>
                            <strong>{item.title}</strong> {item.description}
                        </li>
                    ))}
                </ul>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("privacyPolicy.retention.title")}
                </h2>
                <p className="text-body-font">
                    {t("privacyPolicy.retention.text1")}
                </p>
                <p className="text-body-font">
                    {t("privacyPolicy.retention.text2")}
                </p>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("privacyPolicy.transfer.title")}
                </h2>
                <p className="text-body-font">
                    {t("privacyPolicy.transfer.text1")}
                </p>
                <p className="text-body-font">
                    {t("privacyPolicy.transfer.text2")}
                </p>
                <p className="text-body-font">
                    {t("privacyPolicy.transfer.text3")}
                </p>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("privacyPolicy.disclosure.title")}
                </h2>
                <h3 className="font-heading mb-2 text-2xl font-medium leading-8 text-body-font">
                    {t("privacyPolicy.disclosure.businessTitle")}
                </h3>
                <p className="text-body-font">
                    {t("privacyPolicy.disclosure.businessText")}
                </p>
                <h3 className="font-heading mb-2 text-2xl font-medium leading-8 text-body-font">
                    {t("privacyPolicy.disclosure.lawEnforcementTitle")}
                </h3>
                <p className="text-body-font">
                    {t("privacyPolicy.disclosure.lawEnforcementText")}
                </p>
                <h3 className="font-heading mb-2 text-2xl font-medium leading-8 text-body-font">
                    {t("privacyPolicy.disclosure.otherTitle")}
                </h3>
                <p className="text-body-font">
                    {t("privacyPolicy.disclosure.otherText")}
                </p>
                <ul className="list-inside list-disc ps-4">
                    {t("privacyPolicy.disclosure.otherList", {
                        returnObjects: true,
                    }).map((item, index) => (
                        <li className="text-body-font" key={index}>
                            {item}
                        </li>
                    ))}
                </ul>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("privacyPolicy.security.title")}
                </h2>
                <p className="text-body-font">
                    {t("privacyPolicy.security.text")}
                </p>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("privacyPolicy.children.title")}
                </h2>
                <p className="text-body-font">
                    {t("privacyPolicy.children.text1")}
                </p>
                <p className="text-body-font">
                    {t("privacyPolicy.children.text2")}
                </p>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("privacyPolicy.links.title")}
                </h2>
                <p className="text-body-font">
                    {t("privacyPolicy.links.text1")}
                </p>
                <p className="text-body-font">
                    {t("privacyPolicy.links.text2")}
                </p>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("privacyPolicy.changes.title")}
                </h2>
                <p className="text-body-font">
                    {t("privacyPolicy.changes.text1")}
                </p>
                <p className="text-body-font">
                    {t("privacyPolicy.changes.text2")}
                </p>
            </section>

            <section className="py-5">
                <h2 className="font-heading mb-4 text-3xl font-medium leading-10 text-body-font">
                    {t("privacyPolicy.contact.title")}
                </h2>
                <p className="text-body-font">
                    {t("privacyPolicy.contact.text1")}
                </p>

                <p className="font-lato ms-6 text-justify text-lg font-normal leading-relaxed text-body-font">
                    {t("privacyPolicy.contact.email")}
                    <a
                        href="mailto:contact@nexumind.com"
                        className="text-primary-orange hover:underline"
                    >
                        {t("privacyPolicy.contact.nexumind_email")}
                    </a>
                </p>
            </section>
        </div>
    );
};

export default PrivacyPolicyPage;
