import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CategoryCard from "./CategoryCard.jsx";

const MainCategories = ({ subcategories }) => {
    const { t } = useTranslation();

    return (
        <section className="container mx-auto px-4 py-8">
            <h2 className="mb-8 text-2xl text-primary-green">
                {t("main_categories.header")}
            </h2>
            <div className="grid grid-cols-2 gap-x-4 gap-y-6 md:grid-cols-3 md:gap-16 lg:gap-20 justify-items-center">
                {subcategories.map((subcategory, index) => (
                    <CategoryCard
                        key={subcategory.id}
                        subcategory={subcategory}
                        index={index}
                    />
                ))}
            </div>
        </section>
    );
};

MainCategories.propTypes = {
    subcategories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            // Add other required properties here
        }),
    ).isRequired,
};

export default MainCategories;
