import { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher.jsx";

export default function MobileNavigation({
    categories,
    goToCategory,
    goToOffers,
    isNavOpen,
    setIsNavOpen,
}) {
    const { t } = useTranslation();

    const [expandedSubcategories, setExpandedSubcategories] = useState({});

    // to toggles the expanded state for a specific subcategory1 based on its ID.
    const toggleSubcategory = (id) => {
        // Update the state of expandedSubcategories
        setExpandedSubcategories((prevState) => ({
            // Spread operator to copy all previous state properties
            ...prevState,
            // Toggle the boolean value of the subcategory with the given id
            [id]: !prevState[id],
        }));
    };

    const location = useLocation();
    return (
        <div
            className={`fixed start-0 top-0 z-40 w-screen shadow-xl ${isNavOpen ? "hidden max-md:flex" : "hidden"}`}
        >
            <div className="z-50 max-h-screen w-[320px] overflow-y-auto bg-white">
                <div className="sticky top-0 z-50 mb-2 border-b bg-white">
                    <div className="flex items-center justify-between px-2 py-3">
                        <Link
                            className="relative w-36 overflow-hidden"
                            to={"/"}
                        >
                            <img
                                className="h-12 w-40 object-contain pe-8 ps-2"
                                src={`/image/logo.svg`}
                                alt="Logo"
                                sizes="(min-width: 340px) 160px, calc(70vw - 93px)"
                                loading="lazy"
                            />
                        </Link>
                        {/* <h3 className="text-red text-lg font-semibold">
                        Welcome
                    </h3> */}
                        <svg
                            className="cursor-pointer text-body-font"
                            height="1.4rem"
                            width="1.4rem"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            onClick={() => setIsNavOpen(false)}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </div>
                    {/* user section */}
                    {/* <div className="mx-2 mb-2 grid grid-cols-2 gap-2">
                    <button className="rounded-md bg-blue-500 py-2 font-semibold text-white">
                        Login
                    </button>
                </div> */}
                </div>

                <ul className="flex items-center justify-between border-b px-2 py-1">
                    {/* user section */}
                    <li>
                        <a
                            className="flex cursor-not-allowed items-center"
                            title="coming soon"
                            href="#"
                        >
                            <img
                                src="/image/user.svg"
                                alt="user icon"
                                className="mx-2 h-6 w-6"
                            />
                            <span className="font-body-ar text-primary-green">
                                {" "}
                                {t("login")}
                            </span>
                        </a>
                    </li>

                    {/* translate section */}
                    <li>
                        <LanguageSwitcher setIsNavOpen={setIsNavOpen} />
                    </li>
                </ul>

                {/* other link */}
                <ul className="border-b py-2">
                    {/* Home */}
                    <li className="flex items-center py-2">
                        <NavLink
                            to="/"
                            className={({ isActive }) =>
                                `font-heading mx-2 cursor-pointer font-medium leading-normal ${
                                    isActive
                                        ? "font-semibold text-primary-orange hover:underline"
                                        : "text-gray-600 hover:underline"
                                }`
                            }
                            onClick={() => setIsNavOpen(false)}
                        >
                            {t("home")}
                        </NavLink>
                    </li>

                    {/* deals */}
                    <li className="flex items-center py-2">
                        <button
                            type="button"
                            onClick={goToOffers}
                            className="font-heading mx-2 cursor-pointer font-medium leading-normal text-gray-600 hover:underline"
                        >
                            {t("deals_offers")}
                        </button>
                    </li>

                    {/* stores */}
                    <li className="flex items-center py-2">
                        <NavLink
                            to="/stores"
                            className={({ isActive }) =>
                                `font-heading mx-2 cursor-pointer font-medium leading-normal ${
                                    isActive
                                        ? "font-semibold text-primary-orange hover:underline"
                                        : "text-gray-600 hover:underline"
                                }`
                            }
                            onClick={() => setIsNavOpen(false)}
                        >
                            {t("stores")}
                        </NavLink>
                    </li>

                    {/* about us */}
                    <li className="flex items-center py-2">
                        <NavLink
                            to="/about"
                            className={({ isActive }) =>
                                `font-heading mx-2 cursor-pointer font-medium leading-normal ${
                                    isActive
                                        ? "font-semibold text-primary-orange hover:underline"
                                        : "text-gray-600 hover:underline"
                                }`
                            }
                            onClick={() => setIsNavOpen(false)}
                        >
                            {t("about_us")}
                        </NavLink>
                    </li>

                    {/* translate section */}
                    {/* <li className="py-2">
                        <LanguageSwitcher setIsNavOpen={setIsNavOpen} />
                    </li> */}

                    {/* user section */}
                    {/* <li className="py-2">
                        <a
                            className="flex cursor-not-allowed items-center"
                            title="coming soon"
                            href="#"
                        >
                            <img
                                src="/image/user.svg"
                                alt="user icon"
                                className="mx-2 h-6 w-6"
                            />
                            <span className="text-primary-green">
                                {" "}
                                {t("login")}
                            </span>
                        </a>
                    </li> */}
                </ul>

                {/* All categories */}
                <ul className="border-b px-2 py-2">
                    <div className="flex items-start bg-primary-green/20 py-2">
                        <button
                            type="button"
                            className={`font-heading mx-2 font-semibold text-primary-green ${
                                location.pathname === "/product"
                                    ? "text-primary-green hover:underline"
                                    : "text-gray-600 hover:underline"
                            }`}
                            onClick={() => setIsNavOpen(!isNavOpen)}
                        >
                            {t("categories")}{" "}
                        </button>

                        {/* <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            className={`transition-all duration-500 ${
                                isNavOpen ? "rotate-180" : "rotate-0"
                            }`}
                            height="1.2rem"
                            width="1.2rem"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                        </svg> */}
                    </div>

                    {categories.map((category) =>
                        category.subcategories_level1.map((subcategory1) => (
                            <li
                                className="group py-2 ps-4"
                                key={subcategory1.id}
                            >
                                <div className="flex items-center justify-between">
                                    <button
                                        onClick={() =>
                                            goToCategory(subcategory1.id)
                                        }
                                        type="button"
                                        className="font-lato cursor-pointer text-start text-sm font-semibold leading-normal text-body-font"
                                    >
                                        {subcategory1.name}
                                    </button>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 24 24"
                                        className={`cursor-pointer transition-all duration-500 ${
                                            expandedSubcategories[
                                                subcategory1.id
                                            ]
                                                ? "rotate-180"
                                                : "rotate-0"
                                        }`}
                                        height="20px"
                                        width="20px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        onClick={() =>
                                            toggleSubcategory(subcategory1.id)
                                        }
                                    >
                                        <path
                                            fill="none"
                                            d="M0 0h24v24H0V0z"
                                        ></path>
                                        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                                    </svg>
                                </div>
                                <ul
                                    className={`transition-all duration-500 ${
                                        expandedSubcategories[subcategory1.id]
                                            ? "block"
                                            : "hidden"
                                    } border-b p-1.5`}
                                >
                                    {subcategory1.subcategories_level2 &&
                                        subcategory1.subcategories_level2.map(
                                            (sub) => (
                                                <li
                                                    className="font-lato group mb-3 text-sm font-normal leading-tight text-body-font"
                                                    key={sub.id}
                                                >
                                                    <span
                                                        className="cursor-pointer"
                                                        onClick={() =>
                                                            goToCategory(
                                                                sub.parent_category_id,
                                                                sub.id,
                                                            )
                                                        }
                                                    >
                                                        {sub.name}
                                                    </span>
                                                </li>
                                            ),
                                        )}
                                </ul>
                            </li>
                        )),
                    )}
                </ul>
            </div>
            <div
                onClick={() => setIsNavOpen(false)}
                className="h-screen flex-1 bg-black opacity-50"
            ></div>
        </div>
    );
}

MobileNavigation.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            subcategories_level1: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    parent_category_id: PropTypes.number.isRequired,
                    subcategories_level2: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.number.isRequired,
                            name: PropTypes.string.isRequired,
                            parent_category_id: PropTypes.number.isRequired,
                        }),
                    ),
                }),
            ).isRequired,
        }),
    ).isRequired,
    goToCategory: PropTypes.func.isRequired,
    goToOffers: PropTypes.func.isRequired,
    isNavOpen: PropTypes.bool.isRequired,
    setIsNavOpen: PropTypes.func.isRequired,
};
