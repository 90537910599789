import PropTypes from "prop-types";
import { useState } from "react";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { useTranslation } from "react-i18next";

const FilterSection = ({
    title,
    items,
    selectedItems,
    onChange,
    renderItem,
    isOpened = true,
    children,
    maxItems = 8,
}) => {
    const [isOpen, setIsOpen] = useState(isOpened);

    const idPrefix = title.substr(0, 3).toLocaleLowerCase();

    const toggleSection = () => {
        setIsOpen((prev) => !prev);
    };

    const { t } = useTranslation();

    return (
        <div className="border-b-2 p-4 text-body-font">
            {/* title and arrow */}
            <div
                onClick={toggleSection}
                className="mb-3 flex cursor-pointer items-center justify-between"
            >
                <h3 className="font-heading text-lg font-semibold">{title}</h3>
                <svg
                    className={`h-5 w-5 transform transition-transform duration-300 ${
                        isOpen ? "rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </div>

            {/* list item */}
            <div
                // overflow-y-scroll
                className={`transition-max-height overflow-hidden duration-500 ${
                    // "max-h-fit"
                    isOpen ? "max-h-72" : "max-h-0"
                }`}
                style={{
                    overflowY:
                        items?.length > 0 && items.length > maxItems
                            ? "scroll"
                            : "hidden",
                }}
            >
                {title === t("filter.price") && !items ? (
                    children
                ) : items && items.length > 0 ? (
                    items.map((item) =>
                        renderItem ? (
                            renderItem(
                                item,
                                selectedItems.includes(item.id),
                                onChange,
                            )
                        ) : (
                            <div
                                className="mb-3 flex items-center hover:text-primary-green"
                                key={item.id}
                            >
                                <input
                                    id={idPrefix + item.id}
                                    value={item.name}
                                    type="checkbox"
                                    checked={selectedItems.includes(item.id)}
                                    onChange={() => onChange(item.id)}
                                    className="h-4 w-4 cursor-pointer rounded border outline-none"
                                />
                                <label
                                    htmlFor={idPrefix + item.id}
                                    className="font-lato mx-2 cursor-pointer"
                                >
                                    {capitalizeFirstLetter(item.name)}
                                </label>
                            </div>
                        ),
                    )
                ) : (
                    <div className="text-center text-gray-500">
                        {t("filter.no_data_available", { title: title })}
                    </div>
                )}
            </div>
        </div>
    );
};

FilterSection.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
    selectedItems: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func,
    renderItem: PropTypes.func,
    isOpened: PropTypes.bool,
    children: PropTypes.node,
    maxItems: PropTypes.number,
};

export default FilterSection;
